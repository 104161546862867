import {Tab} from '../models/tabs.model';
import {EViewRoutes} from "./enum";
import {EProtectedActions} from "./protected-actions";

export const CAMPAIGN_OVERVIEW_TABS: Tab[] = [
  {icon: 'description', text: 'details'},
  {icon: 'article', text: 'contentAndProducts'},
  {icon: 'suitability_review', text: 'suitabilityReview'},
  {icon: 'business_center', text: 'selectAndSendToClients'},
  {icon: 'card_travel', text: 'selectAndSendToIntermediaries'},
  {icon: 'account_circle', text: 'allowedUsers', permission: EProtectedActions.viewCampaignAllowedUsers},
];

export const CAMPAIGN_OVERVIEW_PREVIEW_TABS: Tab[] = [
  {icon: 'description', text: 'details'},
  {icon: 'article', text: 'contentAndProducts'},
  {icon: 'suitability_review', text: 'suitabilityReview'},
  {icon: 'business_center', text: 'portfoliosPreview'},
  {icon: 'business_icon', text: 'intermediariesPreview'},
  {icon: 'account_circle', text: 'allowedUsers', permission: EProtectedActions.viewCampaignAllowedUsers},
];

export const CAMPAIGN_COMPACT_OVERVIEW_TABS: Tab[] = [
  {icon: 'article', text: 'viewContentAndMessage'},
  {icon: 'business_center', text: 'selectAndSendToClients'},
  {icon: 'card_travel', text: 'selectAndSendToIntermediaries'},
];

export const CAMPAIGN_COMPACT_PRODUCT_TABS: Tab[] = [
  {icon: 'buy', text: 'distributorSummaryBuy'},
  {icon: 'sell', text: 'distributorSummarySell'},
];

export const CAMPAIGN_COMPACT_ADDITIONAL_INFORMATION: Tab[] = [
  {icon: 'description', text: 'details'},
  {icon: 'link', text: 'links'},
];

export const CAMPAIGN_LAUNCH_TABS: Tab[] = [
  {icon: 'carbon_copy', text: 'ccFramework'},
  {icon: 'preview', text: 'notificationPreviews'},
];

export const DECENTRALIZED_CAMPAIGN_OVERVIEW_TABS: Tab[] = [
  {icon: 'description', text: 'details'},
  {icon: 'article', text: 'contentAndProducts'},
  {icon: 'suitability_review', text: 'suitabilityReview'},
  {icon: 'business_center', text: 'allPortfoliosClient'},
  {icon: 'card_travel', text: 'intermediaries'},
  {icon: 'account_circle', text: 'allowedUsers', permission: EProtectedActions.decentralizedCampaignEdit},
];

export const DECENTRALIZED_CAMPAIGN_OVERVIEW_PREVIEW_TABS: Tab[] = [
  {icon: 'description', text: 'details'},
  {icon: 'article', text: 'contentAndProducts'},
  {icon: 'suitability_review', text: 'suitabilityReview'},
  {icon: 'business_center', text: 'portfoliosPreview'},
  {icon: 'business_icon', text: 'intermediariesPreview'},
  {icon: 'account_circle', text: 'allowedUsers', permission: EProtectedActions.decentralizedCampaignEdit},
];

export const STORY_OVERVIEW_TABS: Tab[] = [
  {icon: 'description', text: 'details'},
  {icon: 'article', text: 'contentAndProducts'},
  {icon: 'business_center', text: 'portfoliosPreview'},
  {icon: 'business_icon', text: 'intermediariesPreview'},
  {icon: 'account_circle', text: 'allowedUsers', permission: EProtectedActions.viewStoryAllowedUsers},
];

export const FILTERING_TABS: Tab[] = [
  {icon: 'description', text: 'details'},
  {icon: 'business_center', text: 'portfoliosPreview'},
  {icon: 'business_icon', text: 'intermediariesPreview'},
];

export const USER_SETTINGS_TABS: Tab[] = [
  {icon: 'people_alt', text: 'collections', routerLink: EViewRoutes.mySettings},
  {icon: 'business_icon', text: 'intermediaryCollections', routerLink: EViewRoutes.myIntermediaryCollections},
  {icon: 'supervised_user_circle', text: 'deputies', routerLink: EViewRoutes.myDynamicUsers},
  {icon: 'monetization_on', text: 'favoriteCurrencies', routerLink: EViewRoutes.myFavoriteCurrencies},
];

export const OBJECTS_TABS: Tab[] = [
  {
    icon: 'people_alt',
    text: 'clients',
    routerLink: EViewRoutes.objectsClients,
    permission: EProtectedActions.viewClients
  },
  {
    icon: 'list_alt',
    text: 'portfolios',
    routerLink: EViewRoutes.objectsPortfolios,
    permission: EProtectedActions.viewPortfolios
  },
  {
    icon: 'dataset',
    text: 'products',
    routerLink: EViewRoutes.objectsProducts,
    permission: EProtectedActions.viewProducts
  },
  {
    icon: 'business_icon',
    text: 'intermediaries',
    routerLink: EViewRoutes.objectsIntermediaries,
    permission: EProtectedActions.viewIntermediaries
  },
  {
    icon: 'contacts',
    text: 'employees',
    routerLink: EViewRoutes.objectsEmployees,
    permission: EProtectedActions.viewEmployees
  },
];

export const SETTINGS_TABS: Tab[] = [
  {icon: 'people_alt', text: 'users'},
  {icon: 'waving_hand', text: 'salutations'},
  {icon: 'read_more', text: 'templateDefaults'},
  {icon: 'storage', text: 'buckets'},
  {icon: 'filter_alt', text: 'filterConfig'},
  {icon: 'carbon_copy', text: 'ccFramework'},
  {icon: 'text_fields', text: 'fieldDefinitions'},
  {icon: 'contact_mail', text: 'contentDefinitions'},
  {icon: 'mail', text: 'appMessages'},
  {icon: 'send', text: 'outgoingMessages'},
  {icon: 'carbon_copy', text: 'sentMessages'},
  {icon: 'stacked_bar_chart', text: 'monitoringOutgoingMessages'},
  {icon: 'info', text: 'systemStatus'},
  {icon: 'article', text: 'reportRequestCreator'},
  {icon: 'article', text: 'reportRequest'},
  {icon: 'article', text: 'reportLog'}
];

export const CLIENT_TABS: Tab[] = [
  {icon: 'description', text: 'details'},
  {icon: 'list', text: 'allActions'},
  {icon: 'campaign', text: 'allCampaigns'},
  {icon: 'list_alt', text: 'allPortfolios'},
];

export const EMPLOYEE_TABS: Tab[] = [
  {icon: 'description', text: 'details'},
  {icon: 'list', text: 'allActions'},
  {icon: 'campaign', text: 'allCampaigns'},
];

export const INTERMEDIARY_TABS: Tab[] = [
  {icon: 'description', text: 'details'},
  {icon: 'contacts', text: 'allEmployees'},
  {icon: 'campaign', text: 'allCampaigns'},
];

export const DEFAULT_PORTFOLIO_TABS: Tab[] = [
  {icon: 'description', text: 'details'},
  {icon: 'campaign', text: 'allCampaigns'},
  {icon: 'supervised_user_circle', text: 'allClients'},
];

export const INTERMEDIARY_PORTFOLIO_TABS: Tab[] = [
  {icon: 'description', text: 'details'},
  {icon: 'campaign', text: 'allCampaigns'},
  {icon: 'business_icon', text: 'allIntermediaries'},
];


export const MONITORING_TABS: Tab[] = [
  {
    icon: 'monitoring_dashboard',
    text: 'dashboard',
    routerLink: EViewRoutes.monitoringCampaign,
    permission: EProtectedActions.viewMonitoring,
  },
  {
    icon: 'supervised_user_circle',
    text: 'relationshipManagers',
    routerLink: EViewRoutes.monitoringRelationshipManager,
    permission: EProtectedActions.viewMonitoring,
  },
  {
    icon: 'campaign',
    text: 'decentralizedCampaigns',
    routerLink: EViewRoutes.monitoringCampaignDecentralized,
    permission: EProtectedActions.decentralizedCampaignMonitoring,
  },
];

export const DISTRIBUTOR_TABS: Tab[] = [
  {
    icon: 'people_alt',
    text: 'clients',
    routerLink: EViewRoutes.distributorClients,
    permission: EProtectedActions.viewClients
  },
  {
    icon: 'list_alt',
    text: 'portfolios',
    routerLink: EViewRoutes.distributorPortfolios,
    permission: EProtectedActions.viewPortfolios,
    hidden: true,
  },
  {
    icon: 'business_icon',
    text: 'intermediaries',
    routerLink: EViewRoutes.distributorIntermediaries,
    permission: EProtectedActions.viewIntermediaries
  },
  {
    icon: 'contacts',
    text: 'employees',
    routerLink: EViewRoutes.distributorEmployees,
    permission: EProtectedActions.viewEmployees,
    hidden: true,
  },
];
